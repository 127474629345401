<template>
  <div>
    <h1 class="heading color-primary mb-8 mb-tablet-16">
      <span class="tablet-desktop">prawo Unii Europejskiej</span>
      <span class="heading__sub">aktualności</span>
    </h1>

<!--    <item-nav-->
<!--      only-other-->
<!--      acts="ue"-->
<!--      noActs="noUe"-->
<!--      notMonitored="UE_NOT_MONITORED"-->
<!--      map="UE_MAP"-->
<!--      class="mobile mb-8"-->
<!--    />-->

    <div class="item-list">
      <div v-if="loading">
        <base-spinner large class="mb-32" />
      </div>
      <div v-else class="mb-8 mb-tablet-32">
        <european-union-acts-list :items="items" class="mb-4" />

        <base-more-button
          v-if="items.length < count"
          @click="loadNext"
          :loading="loadingPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EuropeanUnionActsList from './EuropeanUnionActsList';
import { mapEuropeanUnionActsItems } from './mapEuropeanUnionActsItems';

export default {
  components: {
    EuropeanUnionActsList,
  },
  metaInfo() {
    return {
      title: 'Prawo Unii Europejskiej',
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  data() {
    return {
      loading: false,
      loadingPage: false,
      items: [],
      page: 1,
      count: 0,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.$store.dispatch('bookmarks/fetchEuropeanUnionActs');
      await this.loadPage();
      this.loading = false;
    },
    async loadPage() {
      const url =
        this.page === 1
          ? 'v1/european_union_acts_header/'
          : `v1/european_union_acts_header/?page=${this.page}`;
      const { data } = await this.$api.get(url);
      this.count = data.count;
      this.page++;
      this.items = this.items.concat(mapEuropeanUnionActsItems(data.results));
    },
    async loadNext() {
      if (this.page > 2 && !this.$store.getters['user/isLoggedIn']) {
        this.$store.commit('openGuestModal');
        return;
      }
      const scrollPosition = Math.round(window.scrollY);
      this.loadingPage = true;
      await this.loadPage();
      this.loadingPage = false;
      this.$nextTick(() => {
        window.scrollTo(0, scrollPosition);
      });
    },
  },
};
</script>
